import api from "./api";

interface LoginRequest {
  email: string;
  password: string;
}

export const adminApis = {
  login: (data: LoginRequest) => api.post("/auth", data),
};

export default adminApis;
