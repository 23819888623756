import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="min-h-[80vh] flex items-center justify-center px-4">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-900 dark:text-white">
          404
        </h1>
        <p className="mt-4 text-2xl font-semibold text-gray-700 dark:text-gray-300">
          Page not found!
        </p>
        <p className="mt-2 text-lg text-gray-600 dark:text-gray-400">
          The page you're looking for doesn't exist or has been moved.
        </p>
        <Link
          to="/"
          className="mt-8 inline-block px-6 py-3 bg-black text-white dark:bg-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200"
        >
          Go Back Home
        </Link>
      </div>
    </div>
  );
};
