import { useState } from "react";
import contactApis from "../services/contact.service";
import { showErrorToast, showSuccessToast } from "../utils/toast.util";
import { useTheme } from "../contexts/ThemeContext";

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

export const Contribute = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useTheme();

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_TITLE_LENGTH) {
      setTitle(newValue);
    }
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    if (newValue.length <= MAX_DESCRIPTION_LENGTH) {
      setDescription(newValue);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await contactApis.createContact({
        title,
        description,
      });
      showSuccessToast("Thank you for your contribution request!", isDarkMode);
      setTitle("");
      setDescription("");
    } catch (error) {
      showErrorToast(
        "Failed to submit your request. Please try again.",
        isDarkMode
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-16">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-semibold text-gray-900 dark:text-white mb-4">
          Contribute to Español101.com
        </h1>
        <p className="text-lg text-gray-600 dark:text-gray-300">
          Want to help make Spanish learning better for everyone? Share your
          resource suggestions with me using the form below.
        </p>
      </div>

      <div className="bg-white dark:bg-[#111111] rounded-xl shadow-sm p-8 border border-gray-00 dark:border-gray-600">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <div className="flex justify-between items-center mb-2">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Title
              </label>
              <span
                className={`text-xs ${
                  title.length >= MAX_TITLE_LENGTH * 0.9
                    ? "text-red-500 dark:text-red-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
              >
                {title.length}/{MAX_TITLE_LENGTH}
              </span>
            </div>
            <input
              type="text"
              id="title"
              required
              value={title}
              onChange={handleTitleChange}
              placeholder="Enter the title of your suggestion"
              maxLength={MAX_TITLE_LENGTH}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-[#2e2e2e] text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-400 transition-colors duration-200"
            />
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 dark:text-gray-300"
              >
                Description
              </label>
              <span
                className={`text-xs ${
                  description.length >= MAX_DESCRIPTION_LENGTH * 0.9
                    ? "text-red-500 dark:text-red-400"
                    : "text-gray-500 dark:text-gray-400"
                }`}
              >
                {description.length}/{MAX_DESCRIPTION_LENGTH}
              </span>
            </div>
            <textarea
              id="description"
              required
              value={description}
              onChange={handleDescriptionChange}
              placeholder="Tell us more about your suggestion..."
              rows={6}
              maxLength={MAX_DESCRIPTION_LENGTH}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-[#2e2e2e] text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-400 transition-colors duration-200 resize-none"
            />
          </div>

          <div className="flex justify-start">
            <button
              type="submit"
              disabled={loading}
              className="px-6 py-3 bg-black text-white rounded-lg dark:bg-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Submitting..." : "Submit Suggestion"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
