import { Modal } from "./Modal";
import { Contact } from "../types/api.types";

interface ContactModalProps {
  isOpen: boolean;
  onClose: () => void;
  contact: Contact | null;
  onMarkAsRead: (contactId: string, currentStatus: boolean) => void;
}

export const ContactModal = ({
  isOpen,
  onClose,
  contact,
  onMarkAsRead,
}: ContactModalProps) => {
  if (!contact) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Contact Details">
      <div className="space-y-6">
        {/* Status Badge */}
        <div>
          <span
            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
              contact.isRead
                ? "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200"
                : "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200"
            }`}
          >
            {contact.isRead ? "Read" : "Unread"}
          </span>
        </div>

        {/* Title */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Title
          </h3>
          <p className="mt-1 text-lg font-semibold text-gray-900 dark:text-white">
            {contact.title}
          </p>
        </div>

        {/* Description */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Description
          </h3>
          <p className="mt-1 text-gray-900 dark:text-white whitespace-pre-wrap">
            {contact.description}
          </p>
        </div>

        {/* Date */}
        <div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">
            Submitted on
          </h3>
          <p className="mt-1 text-gray-900 dark:text-white">
            {new Date(contact.createdAt).toLocaleString()}
          </p>
        </div>

        {/* Actions */}
        <div className="flex justify-end space-x-4 pt-4">
          <button
            onClick={() => onMarkAsRead(contact.contactId, contact.isRead)}
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
              contact.isRead
                ? "bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-200 hover:bg-yellow-200 dark:hover:bg-yellow-800"
                : "bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-200 hover:bg-green-200 dark:hover:bg-green-800"
            }`}
          >
            Mark as {contact.isRead ? "Unread" : "Read"}
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg text-sm font-medium hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
