import { useEffect, useState } from "react";
import resourceApis from "../services/resource.service";
import { GetResourcesResponseBody } from "../types/api.types";
import { LanguageLevels, ResourceTypes } from "../constants/enums";
import { Pagination } from "../components/Pagination";
import {
  capitalize,
  getResourceTypeIcon,
} from "../utils/function.util";

type ResourceItem = GetResourcesResponseBody["data"]["resources"][0];

type Filters = {
  type?: ResourceTypes;
  levels: LanguageLevels[];
};

type SortOption =
  | "createdAt_DESC"
  | "createdAt_ASC"
  | "clicksCount_DESC"
  | null;

export const Home = () => {
  const [resources, setResources] = useState<ResourceItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState<Filters>({
    type: undefined,
    levels: [],
  });
  const [sortBy, setSortBy] = useState<SortOption>(null);

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    loadResources();
  }, [currentPage, filters, sortBy]);

  const loadResources = async () => {
    setLoading(true);
    try {
      const filterParts: string[] = [];
      if (filters.type) {
        filterParts.push(`type_${filters.type.toLowerCase()}`);
      }
      filters.levels.forEach((level) =>
        filterParts.push(`level_${level.toLowerCase()}`)
      );
      const filterString =
        filterParts.length > 0 ? filterParts.join(",") : undefined;

      const response = await resourceApis.getResources({
        page: currentPage,
        limit: ITEMS_PER_PAGE,
        filter: filterString,
        sortBy: sortBy,
      });
      setResources(response.data.data.resources);
      setTotalPages(
        Math.ceil(response.data.data.resourceCount / ITEMS_PER_PAGE)
      );
    } catch (error) {
      console.error("Failed to load resources:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleTypeFilter = (type: ResourceTypes) => {
    setFilters((prev) => ({
      ...prev,
      type: prev.type === type ? undefined : type,
    }));
    setCurrentPage(1);
  };

  const handleLevelFilter = (level: LanguageLevels) => {
    setFilters((prev) => ({
      ...prev,
      levels: prev.levels.includes(level)
        ? prev.levels.filter((l) => l !== level)
        : [...prev.levels, level],
    }));
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters({ type: undefined, levels: [] });
    setCurrentPage(1);
  };

  const handleResourceClick = async (resourceId: string, url: string) => {
    // Fire and forget - no need to await or handle errors
    resourceApis.increaseResourceClicksCount(resourceId);
    window.open(url, "_blank");
  };

  const handleSortClick = (option: SortOption) => {
    setSortBy((current) => (current === option ? null : option));
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="text-center py-16">
        <div className="max-w-4xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-semibold text-gray-900 dark:text-white mb-6 transition-colors duration-200">
            Spanish Learning Made Simple—One Platform, Countless Free Resources!
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 transition-colors duration-200">
            Explore, learn, and fall in love with Spanish! Espanol101.com brings
            all the best free resources together for you.
          </p>
        </div>
      </section>

      {/* Filters Section */}
      <section className="max-w-6xl mx-auto px-4 mb-8">
        <div className="bg-white dark:bg-[#111111] rounded-xl shadow-md p-6 border border-gray-300 dark:border-gray-600">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              Filter Resources
            </h2>
            {(filters.type || filters.levels.length > 0) && (
              <button
                onClick={clearFilters}
                className="text-sm text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white transition-colors duration-200"
              >
                Clear all filters
              </button>
            )}
          </div>

          <div className="space-y-6">
            {/* Sort Section */}
            <div className="mb-6">
              <h3 className="text-sm font-medium text-gray-700 dark:text-white mb-3">
                Sort By
              </h3>
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => handleSortClick("clicksCount_DESC")}
                  className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                    sortBy === "clicksCount_DESC"
                      ? "bg-black text-white dark:bg-white dark:text-black"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-[#2e2e2e] dark:text-white dark:hover:bg-gray-600"
                  }`}
                >
                  <span className="mr-2">🔥</span>
                  Most Popular
                </button>
                <button
                  onClick={() => handleSortClick("createdAt_DESC")}
                  className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                    sortBy === "createdAt_DESC"
                      ? "bg-black text-white dark:bg-white dark:text-black"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-[#2e2e2e] dark:text-white dark:hover:bg-gray-600"
                  }`}
                >
                  Newest
                </button>
                <button
                  onClick={() => handleSortClick("createdAt_ASC")}
                  className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                    sortBy === "createdAt_ASC"
                      ? "bg-black text-white dark:bg-white dark:text-black"
                      : "bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-[#2e2e2e] dark:text-white dark:hover:bg-gray-600"
                  }`}
                >
                  Oldest
                </button>
              </div>
            </div>

            {/* Resource Types */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                Resource Type
              </h3>
              <div className="flex flex-wrap gap-2">
                {Object.values(ResourceTypes).map((type) => (
                  <button
                    key={type}
                    onClick={() => handleTypeFilter(type)}
                    className={`inline-flex items-center px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                      filters.type === type
                        ? "bg-black text-white dark:bg-white dark:text-black"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-[#2e2e2e] dark:text-gray-300 dark:hover:bg-gray-600"
                    }`}
                  >
                    <span className="mr-2">{getResourceTypeIcon(type)}</span>
                    {capitalize(type)}
                  </button>
                ))}
              </div>
            </div>

            {/* Language Levels */}
            <div>
              <h3 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-3">
                Language Level
              </h3>
              <div className="flex flex-wrap gap-2">
                {Object.values(LanguageLevels).map((level) => (
                  <button
                    key={level}
                    onClick={() => handleLevelFilter(level)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
                      filters.levels.includes(level)
                        ? "bg-black text-white dark:bg-white dark:text-black"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200 dark:bg-[#2e2e2e] dark:text-gray-300 dark:hover:bg-gray-600"
                    }`}
                  >
                    {level}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Resources Section */}
      <section className="max-w-6xl mx-auto px-4 pb-16">
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-black dark:border-white mx-auto transition-colors duration-200"></div>
            <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
              Loading resources...
            </p>
          </div>
        ) : (
          <div className="space-y-8">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {resources.map((resource) => (
                <a
                  key={resource.resourceId}
                  onClick={() =>
                    handleResourceClick(resource.resourceId, resource.url)
                  }
                  className="group flex items-center gap-3 border border-gray-200 dark:border-gray-700 rounded-lg p-4 hover:border-gray-400 dark:hover:border-gray-600 transition-colors duration-200 bg-white dark:bg-[#111111] cursor-pointer"
                >
                  <span
                    className="text-xl dark:text-gray-300"
                    role="img"
                    aria-label={resource.type}
                  >
                    {getResourceTypeIcon(resource.type)}
                  </span>
                  <div className="flex-1 min-w-0">
                    <p className="text-gray-900 dark:text-white text-sm font-medium truncate">
                      {resource.title}
                    </p>
                    <p className="text-gray-500 dark:text-gray-400 text-xs">
                      {resource.levels.join(", ")}
                    </p>
                  </div>
                </a>
              ))}
            </div>
            {resources.length === 0 ? (
              <div className="text-center text-gray-500 dark:text-gray-400">
                No resources found with the selected filters.
              </div>
            ) : (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </section>
    </div>
  );
};
