import { toast, ToastOptions } from "react-toastify";

// Toast yapılandırması
export const getToastConfig = (isDarkMode: boolean): ToastOptions => ({
  position: "bottom-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: isDarkMode ? "dark" : "light",
});

// Toast helper fonksiyonları
export const showSuccessToast = (message: string, isDarkMode: boolean) => {
  toast.success(message, getToastConfig(isDarkMode));
};

export const showErrorToast = (message: string, isDarkMode: boolean) => {
  toast.error(message, getToastConfig(isDarkMode));
};

export const showInfoToast = (message: string, isDarkMode: boolean) => {
  toast.info(message, getToastConfig(isDarkMode));
};

export const showWarningToast = (message: string, isDarkMode: boolean) => {
  toast.warning(message, getToastConfig(isDarkMode));
};
