import { ReactElement } from "react";
import { ResourceTypes } from "../constants/enums";
import {
  BookRounded,
  PhoneIphoneRounded,
  PlayCircleFilledRounded,
  PodcastsRounded,
  LanguageRounded,
  SmartDisplayRounded,
  TvRounded,
  LibraryBooksRounded,
  MenuBookRounded,
  SchoolRounded,
  LibraryMusicRounded,
  EditRounded,
} from "@mui/icons-material";

export const getResourceTypeIcon = (
  type: ResourceTypes
): ReactElement<any, any> => {
  switch (type) {
    case ResourceTypes.VIDEO:
      return <PlayCircleFilledRounded />;
    case ResourceTypes.PODCAST:
      return <PodcastsRounded />;
    case ResourceTypes.APP:
      return <PhoneIphoneRounded />;
    case ResourceTypes.WEBSITE:
      return <LanguageRounded />;
    case ResourceTypes.DICTIONARY:
      return <MenuBookRounded />;
    case ResourceTypes.CHANNEL:
      return <SmartDisplayRounded />;
    case ResourceTypes.MOVIE:
      return <TvRounded />;
    case ResourceTypes.READING:
      return <LibraryBooksRounded />;
    case ResourceTypes.TEST:
      return <EditRounded />;
    case ResourceTypes.COURSE:
      return <SchoolRounded />;
    case ResourceTypes.PLAYLIST:
      return <LibraryMusicRounded />;
  }

  return <BookRounded />;
};

export const capitalize = (text: string): string => {
  if (!text) {
    return null;
  }

  const textLowerCase: string = text.toLowerCase();
  return textLowerCase[0].toUpperCase() + textLowerCase.substring(1);
};

export const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};
