import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { articleApis } from "../services/article.service";
import { GetArticlesResponseBody, MAX_TITLE_LENGTH } from "../types/api.types";
import { Pagination } from "../components/Pagination";
import DOMPurify from "dompurify";
import { formatDate } from "../utils/function.util";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";

type ArticleItem = GetArticlesResponseBody["data"]["articles"][0];

export const Articles = () => {
  const navigate = useNavigate();
  const [articles, setArticles] = useState<ArticleItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const ITEMS_PER_PAGE = 10;

  useEffect(() => {
    loadArticles();
  }, [currentPage]);

  const loadArticles = async () => {
    setLoading(true);
    try {
      const response = await articleApis.getArticles({
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });
      setArticles(response.data.data.articles);
      setTotalPages(
        Math.ceil(response.data.data.articleCount / ITEMS_PER_PAGE)
      );
    } catch (error) {
      console.error("Failed to load articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleArticleClick = (articleId: string) => {
    navigate(`/articles/${articleId}`);
  };

  const truncateContent = (content: string, maxLength: number) => {
    try {
      // Try to parse as Draft.js content
      const parsedContent = JSON.parse(content);
      if (parsedContent.blocks) {
        const contentState = convertFromRaw(parsedContent);
        const editorState = EditorState.createWithContent(contentState);

        // Join blocks with spaces between them
        const textContent = parsedContent.blocks
          .map((block: { text: string }) => block.text)
          .join(" ")
          .trim();

        if (textContent.length <= maxLength) return textContent;
        return (
          textContent
            .substring(0, maxLength)
            .trim()
            .replace(/[.,!?]?$/, "") + "..."
        );
      }
      throw new Error("Not valid Draft.js content");
    } catch {
      // If parsing fails or content is not Draft.js format, handle as plain text
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = DOMPurify.sanitize(content);
      const textContent = tempDiv.textContent || tempDiv.innerText || "";

      if (textContent.length <= maxLength) return textContent;
      return (
        textContent
          .substring(0, maxLength)
          .trim()
          .replace(/[.,!?]?$/, "") + "..."
      );
    }
  };

  const formatArticleDate = (dateString: string) => {
    try {
      return formatDate(new Date(dateString));
    } catch (error) {
      console.error("Invalid date:", dateString);
      return "Date unavailable";
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-3">
      <h1 className="text-3xl font-semibold text-gray-900 dark:text-white mb-8">
        Articles
      </h1>

      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 dark:border-gray-600 border-t-gray-900 dark:border-t-white"></div>
        </div>
      ) : (
        <>
          {articles.length === 0 ? (
            <div className="text-center py-12">
              <p className="text-gray-600 dark:text-gray-400 text-lg">
                No articles available yet.
              </p>
            </div>
          ) : (
            <div className="space-y-10">
              {articles.map((article) => (
                <article
                  key={article.articleId}
                  onClick={() => handleArticleClick(article.articleId)}
                  className="group cursor-pointer"
                >
                  <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400 mb-2">
                    <time>{formatArticleDate(article.createdAt)}</time>
                  </div>

                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white group-hover:text-gray-600 dark:group-hover:text-gray-300 mb-1 transition-colors duration-200">
                    {article.title.length > MAX_TITLE_LENGTH
                      ? `${article.title.substring(0, MAX_TITLE_LENGTH)}...`
                      : article.title}
                  </h2>

                  <p className="text-base text-gray-600 dark:text-gray-400 leading-relaxed">
                    {truncateContent(article.content, 180)}
                  </p>

                  <div className="mt-1">
                    <span className="text-sm font-medium text-gray-900 dark:text-white group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors duration-200">
                      Read more →
                    </span>
                  </div>
                </article>
              ))}
            </div>
          )}
          {totalPages > 1 && (
            <div className="mt-12 flex justify-center">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
