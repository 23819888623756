import {
  CreateArticleRequestBody,
  GetArticleResponseBody,
  GetArticlesResponseBody,
  PaginationParams,
  UpdateArticleRequestBody,
} from "../types/api.types";
import api from "./api";

export const articleApis = {
  createArticle: (data: CreateArticleRequestBody) => api.post("/article", data),
  updateArticle: (data: UpdateArticleRequestBody, id: string) =>
    api.patch(`/article/${id}`, data),

  deleteArticle: (id: string) => api.delete(`/article/${id}`),
  getArticle: (id: string) => api.get<GetArticleResponseBody>(`/article/${id}`),
  getArticles: (params?: PaginationParams) =>
    api.get<GetArticlesResponseBody>("/article", {
      params: {
        page: params?.page || 1,
        limit: params?.limit || 20,
        filter: params?.filter,
        sortBy: params?.sortBy,
      },
    }),
};
