import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { ResourceModal } from "../../components/ResourceModal";
import { DeleteConfirmationModal } from "../../components/DeleteConfirmationModal";
import { ContactModal } from "../../components/ContactModal";
import { Pagination } from "../../components/Pagination";
import { ApiError, GetResourcesResponseBody } from "../../types/api.types";
import resourceApis from "../../services/resource.service";
import { useNavigate } from "react-router-dom";
import { LanguageLevels, ResourceTypes } from "../../constants/enums";
import { toast } from "react-toastify";
import { showErrorToast, showSuccessToast } from "../../utils/toast.util";
import { useTheme } from "../../contexts/ThemeContext";
import contactApis from "../../services/contact.service";
import { articleApis } from "../../services/article.service";
import { ArticleModal } from "../../components/admin/ArticleModal";

type ResourceItem = GetResourcesResponseBody["data"]["resources"][0];
type ResourceFormData = {
  title: string;
  description?: string;
  type: ResourceTypes;
  levels: LanguageLevels[];
  logoUrl: string;
  url: string;
  isHighlighted: boolean;
};

type Contact = {
  contactId: string;
  title: string;
  description: string;
  isRead: boolean;
  createdAt: string;
};

type Article = {
  articleId: string;
  title: string;
  content: string;
  createdAt: string;
};

type TabType = "resources" | "contacts" | "articles";

export const Dashboard = () => {
  const { logout, isAuthenticated } = useAuth();
  const [activeTab, setActiveTab] = useState<TabType>("resources");
  const [resources, setResources] = useState<ResourceItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<ResourceItem | null>(
    null
  );
  const [articlesCurrentPage, setArticlesCurrentPage] = useState(1);
  const [articlesTotalPages, setArticlesTotalPages] = useState(1);
  const [modalMode, setModalMode] = useState<"add" | "edit">("add");
  const { isDarkMode } = useTheme();

  const navigate = useNavigate();

  // Contacts state
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsLoading, setContactsLoading] = useState(true);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  // Articles state
  const [articles, setArticles] = useState<Article[]>([]);
  const [articlesLoading, setArticlesLoading] = useState(true);
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
  const [articleModalMode, setArticleModalMode] = useState<"add" | "edit">(
    "add"
  );
  const [isDeleteArticleModalOpen, setIsDeleteArticleModalOpen] =
    useState(false);
  const [articleToDelete, setArticleToDelete] = useState<Article | null>(null);

  const ITEMS_PER_PAGE = 20;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/admin/login");
      return;
    }
    if (activeTab === "resources") {
      loadResources();
    } else if (activeTab === "contacts") {
      loadContacts();
    } else if (activeTab === "articles") {
      loadArticles();
    }
  }, [currentPage, articlesCurrentPage, isAuthenticated, navigate, activeTab]);

  const loadResources = async () => {
    try {
      const response = await resourceApis.getResources({
        page: currentPage,
        limit: ITEMS_PER_PAGE,
      });
      setResources(response.data.data.resources);
      setTotalPages(
        Math.ceil(response.data.data.resourceCount / ITEMS_PER_PAGE)
      );
      setLoading(false);
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(apiError.response.data.message, isDarkMode);
      setLoading(false);
    }
  };

  const loadContacts = async () => {
    setContactsLoading(true);
    try {
      const response = await contactApis.getContacts();
      setContacts(response.data.data.contacts);
    } catch (error) {
      showErrorToast("Failed to load contacts", isDarkMode);
    } finally {
      setContactsLoading(false);
    }
  };

  const loadArticles = async () => {
    setArticlesLoading(true);
    try {
      const response = await articleApis.getArticles({
        page: articlesCurrentPage,
        limit: ITEMS_PER_PAGE,
      });
      setArticles(response.data.data.articles);
      setArticlesTotalPages(
        Math.ceil(response.data.data.articleCount / ITEMS_PER_PAGE)
      );
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(
        apiError.response.data.message || "Failed to load articles",
        isDarkMode
      );
    } finally {
      setArticlesLoading(false);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleAddResource = () => {
    setSelectedResource(null);
    setModalMode("add");
    setIsResourceModalOpen(true);
  };

  const handleEditResource = async (resource: ResourceItem) => {
    try {
      const response = await resourceApis.getResourceById(resource.resourceId);
      const resourceData = response.data.data.resource;
      setSelectedResource({
        resourceId: resourceData.resourceId,
        title: resourceData.title,
        description: resourceData.description,
        type: resourceData.type,
        levels: resourceData.levels,
        logoUrl: resourceData.logoUrl,
        url: resourceData.url,
        clicksCount: resourceData.clicksCount,
        isHighlighted: resourceData.isHighlighted,
      });
      setModalMode("edit");
      setIsResourceModalOpen(true);
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(apiError.response.data.message, isDarkMode);
    }
  };

  const handleDeleteResource = (resource: ResourceItem) => {
    setSelectedResource(resource);
    setIsDeleteModalOpen(true);
  };

  const handleResourceSubmit = async (resourceData: ResourceFormData) => {
    try {
      if (modalMode === "add") {
        await resourceApis.createResource(resourceData);
        showSuccessToast("Resource created successfully", isDarkMode);
      } else if (selectedResource) {
        await resourceApis.updateResource(
          selectedResource.resourceId,
          resourceData
        );
        showSuccessToast("Resource updated successfully", isDarkMode);
      }
      setIsResourceModalOpen(false);
      setSelectedResource(null);
      loadResources();
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(apiError.response.data.message, isDarkMode);
    }
  };

  const handleResourceDelete = async () => {
    if (selectedResource) {
      try {
        await resourceApis.delete(selectedResource.resourceId);
        setIsDeleteModalOpen(false);
        setSelectedResource(null);
        showSuccessToast("Resource deleted successfully", isDarkMode);
        loadResources();
      } catch (error) {
        const apiError = error as ApiError;
        showErrorToast(apiError.response.data.message, isDarkMode);
      }
    }
  };

  const handleModalClose = () => {
    setIsResourceModalOpen(false);
    setSelectedResource(null); // Reset selected resource when modal is closed
  };

  const handleLogout = async () => {
    await logout();
    navigate("/admin/login/txPKGtKCRF8hT9jxCxNg");
  };

  const handleMarkAsRead = async (
    contactId: string,
    currentStatus: boolean
  ) => {
    try {
      await contactApis.updateIsContactRead(contactId, {
        isRead: !currentStatus,
      });
      showSuccessToast("Contact status updated successfully", isDarkMode);
      loadContacts();
    } catch (error) {
      showErrorToast("Failed to update contact status", isDarkMode);
    }
  };

  const handleViewContact = async (contactId: string) => {
    try {
      const response = await contactApis.getContact(contactId);
      setSelectedContact(response.data.data.contact);
      setIsContactModalOpen(true);
    } catch (error) {
      showErrorToast("Failed to load contact details", isDarkMode);
    }
  };

  const handleEditArticle = async (article: Article) => {
    try {
      const response = await articleApis.getArticle(article.articleId);
      const articleData = response.data.data;
      setSelectedArticle({
        articleId: articleData.article.articleId,
        title: articleData.article.title,
        content: articleData.article.content,
        createdAt: articleData.article.createdAt,
      });
      setArticleModalMode("edit");
      setIsArticleModalOpen(true);
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(
        apiError.response.data.message || "Failed to load article details",
        isDarkMode
      );
    }
  };

  const handleArticleSubmit = async (articleData: {
    title: string;
    content: string;
  }) => {
    try {
      if (articleModalMode === "add") {
        await articleApis.createArticle({
          title: articleData.title,
          content: articleData.content,
        });
        showSuccessToast("Article created successfully", isDarkMode);
      } else if (selectedArticle) {
        await articleApis.updateArticle(
          {
            title: articleData.title,
            content: articleData.content,
          },
          selectedArticle.articleId
        );
        showSuccessToast("Article updated successfully", isDarkMode);
      }
      setIsArticleModalOpen(false);
      setSelectedArticle(null);
      loadArticles();
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(
        apiError.response.data.message || "Failed to save article",
        isDarkMode
      );
    }
  };

  const handleAddArticle = () => {
    setSelectedArticle(null);
    setArticleModalMode("add");
    setIsArticleModalOpen(true);
  };

  const handleDeleteArticle = async (article: Article) => {
    setArticleToDelete(article);
    setIsDeleteArticleModalOpen(true);
  };

  const confirmDeleteArticle = async () => {
    if (!articleToDelete) return;

    try {
      await articleApis.deleteArticle(articleToDelete.articleId);
      showSuccessToast("Article deleted successfully", isDarkMode);
      loadArticles();
      setIsDeleteArticleModalOpen(false);
    } catch (error) {
      const apiError = error as ApiError;
      showErrorToast(
        apiError.response.data.message || "Failed to delete article",
        isDarkMode
      );
    } finally {
      setArticleToDelete(null);
    }
  };

  return (
    <div className="min-h-screen bg-white dark:bg-[#111]">
      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setActiveTab("resources")}
              className={`px-4 py-2 rounded-lg font-medium ${
                activeTab === "resources"
                  ? "bg-black text-white dark:bg-white dark:text-black"
                  : "text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              }`}
            >
              Resources
            </button>
            <button
              onClick={() => setActiveTab("contacts")}
              className={`px-4 py-2 rounded-lg font-medium ${
                activeTab === "contacts"
                  ? "bg-black text-white dark:bg-white dark:text-black"
                  : "text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              }`}
            >
              Contacts
            </button>
            <button
              onClick={() => setActiveTab("articles")}
              className={`px-4 py-2 rounded-lg font-medium ${
                activeTab === "articles"
                  ? "bg-black text-white dark:bg-white dark:text-black"
                  : "text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
              }`}
            >
              Articles
            </button>
          </div>
          <div className="flex items-center space-x-4">
            {activeTab === "resources" && (
              <button
                onClick={handleAddResource}
                className="px-4 py-2 bg-black text-white dark:bg-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200"
              >
                Add Resource
              </button>
            )}
            {activeTab === "articles" && (
              <button
                onClick={handleAddArticle}
                className="px-4 py-2 bg-black text-white dark:bg-white dark:text-black rounded-lg hover:bg-gray-800 dark:hover:bg-gray-100 transition-colors duration-200"
              >
                Add Article
              </button>
            )}
            <button
              onClick={handleLogout}
              className="px-4 py-2 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
            >
              Logout
            </button>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-800 shadow-sm rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6 border-b border-gray-200 dark:border-gray-700">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                  {activeTab === "resources"
                    ? "Resources"
                    : activeTab === "contacts"
                    ? "Contacts"
                    : "Articles"}
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {activeTab === "resources"
                    ? "Manage all Spanish learning resources"
                    : activeTab === "contacts"
                    ? "Manage contacts"
                    : "Manage articles"}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-900 px-4 py-5 sm:p-6">
            <div className="space-y-4">
              {activeTab === "resources" ? (
                loading ? (
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    Loading resources...
                  </div>
                ) : resources.length === 0 ? (
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    No resources found. Add your first resource!
                  </div>
                ) : (
                  <>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Title
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Category
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Level
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                          {resources.map((resource) => (
                            <tr key={resource.resourceId}>
                              <td className="px-6 py-4 whitespace-nowrap text-gray-900 dark:text-white">
                                {resource.title}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-gray-300">
                                {resource.type}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-gray-500 dark:text-gray-300">
                                {resource.levels.join(", ").toUpperCase()}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button
                                  onClick={() => handleEditResource(resource)}
                                  className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white mr-4"
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => handleDeleteResource(resource)}
                                  className="text-red-600 dark:text-red-400 hover:text-red-900 dark:hover:text-red-300"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  </>
                )
              ) : activeTab === "contacts" ? (
                contactsLoading ? (
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    Loading contacts...
                  </div>
                ) : contacts.length === 0 ? (
                  <div className="text-center text-gray-500 dark:text-gray-400">
                    No contacts found. Add your first contact!
                  </div>
                ) : (
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead>
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Status
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Title
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Description
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Date
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                        {contacts.map((contact) => (
                          <tr
                            key={contact.contactId}
                            onClick={() => handleViewContact(contact.contactId)}
                            className="cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span
                                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  contact.isRead
                                    ? "bg-green-100 text-green-800"
                                    : "bg-yellow-100 text-yellow-800"
                                }`}
                              >
                                {contact.isRead ? "Read" : "Unread"}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {contact.title}
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-500 dark:text-gray-300 max-w-md truncate">
                                {contact.description}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-500 dark:text-gray-300">
                                {new Date(
                                  contact.createdAt
                                ).toLocaleDateString()}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleMarkAsRead(
                                    contact.contactId,
                                    contact.isRead
                                  );
                                }}
                                className={`${
                                  contact.isRead
                                    ? "text-yellow-600 dark:text-yellow-400 hover:text-yellow-900 dark:hover:text-yellow-300"
                                    : "text-green-600 dark:text-green-400 hover:text-green-900 dark:hover:text-green-300"
                                }`}
                              >
                                Mark as {contact.isRead ? "Unread" : "Read"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )
              ) : articlesLoading ? (
                <div className="text-center py-12">
                  <div className="animate-spin rounded-full h-8 w-8 border-2 border-gray-300 dark:border-gray-600 border-t-gray-900 dark:border-t-white mx-auto"></div>
                  <p className="mt-4 text-sm text-gray-600 dark:text-gray-400">
                    Loading articles...
                  </p>
                </div>
              ) : articles.length === 0 ? (
                <div className="text-center py-12">
                  <p className="text-gray-600 dark:text-gray-400 text-lg">
                    No articles found.
                  </p>
                </div>
              ) : (
                <div className="space-y-6">
                  <div className="overflow-hidden bg-white dark:bg-[#111111] shadow-sm border border-gray-200 dark:border-gray-700 rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead>
                        <tr className="bg-gray-50 dark:bg-gray-800">
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Title
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Content Preview
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Date
                          </th>
                          <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white dark:bg-[#111111] divide-y divide-gray-200 dark:divide-gray-700">
                        {articles.map((article) => (
                          <tr
                            key={article.articleId}
                            className="hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors duration-200"
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900 dark:text-white">
                                {article.title}
                              </div>
                            </td>
                            <td className="px-6 py-4">
                              <div className="text-sm text-gray-500 dark:text-gray-400 max-w-md truncate">
                                {article.content}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-500 dark:text-gray-400">
                                {new Date(
                                  article.createdAt
                                ).toLocaleDateString()}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => handleEditArticle(article)}
                                className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white mr-4"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDeleteArticle(article)}
                                className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {articlesTotalPages > 1 && (
                    <div className="mt-6 flex justify-center">
                      <Pagination
                        currentPage={articlesCurrentPage}
                        totalPages={articlesTotalPages}
                        onPageChange={(page) => {
                          setArticlesCurrentPage(page);
                          window.scrollTo(0, 0);
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <ResourceModal
        isOpen={isResourceModalOpen}
        onClose={handleModalClose}
        onSubmit={handleResourceSubmit}
        resource={selectedResource}
        mode={modalMode}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleResourceDelete}
        title={selectedResource?.title || ""}
      />

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        contact={selectedContact}
        onMarkAsRead={handleMarkAsRead}
      />

      <ArticleModal
        isOpen={isArticleModalOpen}
        onClose={() => {
          setIsArticleModalOpen(false);
          setSelectedArticle(null);
        }}
        onSubmit={handleArticleSubmit}
        initialData={selectedArticle || undefined}
        mode={articleModalMode}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteArticleModalOpen}
        onClose={() => setIsDeleteArticleModalOpen(false)}
        onConfirm={confirmDeleteArticle}
        title="Delete Article"
        message={`Are you sure you want to delete "${articleToDelete?.title}"? This action cannot be undone.`}
      />
    </div>
  );
};
