export enum ResourceTypes {
  WEBSITE = "WEBSITE",
  COURSE = "COURSE",
  CHANNEL = "CHANNEL",
  VIDEO = "VIDEO",
  APP = "APP",
  PODCAST = "PODCAST",
  PLAYLIST = "PLAYLIST",
  READING = "READING",
  TEST = "TEST",
  DICTIONARY = "DICTIONARY",
  MOVIE = "MOVIE",
}

export enum LanguageLevels {
  A1 = "A1",
  A2 = "A2",
  B1 = "B1",
  B2 = "B2",
  C1 = "C1",
  C2 = "C2",
}

export enum ResourceStatuses {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
}
