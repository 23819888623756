export const Footer = () => {
  return (
    <footer className="bg-white dark:bg-[#111] border-t border-gray-200 dark:border-gray-800 transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-center space-y-2">
          <p className="font-semibold text-gray-900 dark:text-white transition-colors duration-200">
            © {new Date().getFullYear()} - Español101.com
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400 transition-colors duration-200">
            Built with ❤️ for the Spanish learning community.
          </p>
        </div>
      </div>
    </footer>
  );
};
