import React from "react";

export const About = () => {
  return (
    <div className="max-w-4xl mx-auto px-6 py-3">
      <h1 className="text-2xl font-extrabold text-gray-900 dark:text-white mb-8">
        What is Español101.com?
      </h1>
      <div className="prose prose-lg dark:prose-invert text-justify leading-relaxed">
        <p>
          Español101.com is a platform dedicated to helping Spanish learners by
          bringing together all the best free resources available online. The
          mission is to make learning Spanish more accessible, efficient, and
          enjoyable for everyone. Whether you’re just starting out or looking to
          deepen your understanding of the language, Español101.com offers a
          curated collection of tools, guides, and materials to support you on
          your journey.
        </p>
        <div className="mt-6 pt-4 text-[0.95rem] text-gray-700 dark:text-gray-300">
          <h6 className="font-semibold text-lg mb-3">
            Developed by{" "}
            <a
              href="https://www.linkedin.com/in/tumeraltunbas/"
              target="_blank"
              rel="noreferrer"
            >
              Tumer Altunbas
            </a>
          </h6>
          <p className="leading-relaxed">
            My fascination with Spanish began in the heart of Spain. I was
            inspired by the lively culture, the friendly people, and the
            beautiful sound of the language. This experience changed me and made
            me passionate about learning Spanish and sharing that journey with
            others. Through Español101.com, I want to offer learners the
            resources and support they need to not only learn the language but
            also enjoy its richness. ¡Vamos!
          </p>
        </div>
      </div>
    </div>
  );
};
