import { useState, useEffect } from "react";
import { Modal } from "./Modal";
import { LanguageLevels, ResourceTypes } from "../constants/enums";
import { GetResourcesResponseBody } from "../types/api.types";

type ResourceItem = GetResourcesResponseBody["data"]["resources"][0];

interface ResourceModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (resource: ResourceFormData) => void;
  resource?: ResourceItem | null;
  mode: "add" | "edit";
}

interface ResourceFormData {
  title: string;
  description: string;
  type: ResourceTypes;
  levels: LanguageLevels[];
  logoUrl: string;
  url: string;
  isHighlighted: boolean;
}

const resourceInitialState = {
  title: "",
  description: "",
  type: ResourceTypes.VIDEO,
  levels: [] as LanguageLevels[],
  logoUrl: "",
  url: "",
  isHighlighted: false,
};

export const ResourceModal = ({
  isOpen,
  onClose,
  onSubmit,
  resource,
  mode,
}: ResourceModalProps) => {
  const [formData, setFormData] =
    useState<ResourceFormData>(resourceInitialState);

  useEffect(() => {
    if (resource && mode === "edit") {
      setFormData({
        title: resource.title,
        description: resource.description || "",
        type: resource.type,
        levels: resource.levels,
        logoUrl: resource.logoUrl,
        url: resource.url,
        isHighlighted: resource.isHighlighted,
      });
    }
  }, [resource, mode]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
    setFormData(resourceInitialState);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={mode === "add" ? "Add Resource" : "Edit Resource"}
    >
      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Title & Description Section */}
        <div className="space-y-6">
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2"
            >
              Title
            </label>
            <input
              type="text"
              id="title"
              required
              placeholder="Enter resource title"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-colors duration-200"
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2"
            >
              Description
            </label>
            <textarea
              id="description"
              placeholder="Enter resource description"
              value={formData.description}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              rows={4}
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-colors duration-200 resize-none"
            />
          </div>
        </div>

        {/* Type & Levels Section */}
        <div className="space-y-6">
          <div>
            <label
              htmlFor="type"
              className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2"
            >
              Resource Type
            </label>
            <select
              id="type"
              required
              value={formData.type}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  type: e.target.value as ResourceTypes,
                })
              }
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-colors duration-200"
            >
              {Object.values(ResourceTypes).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-3">
              Language Levels
            </label>
            <div className="grid grid-cols-3 gap-3">
              {Object.values(LanguageLevels).map((level) => (
                <label
                  key={level}
                  className="relative flex items-center justify-center p-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                >
                  <input
                    type="checkbox"
                    checked={formData.levels.includes(level)}
                    onChange={(e) => {
                      const newLevels = e.target.checked
                        ? [...formData.levels, level]
                        : formData.levels.filter((l) => l !== level);
                      setFormData({ ...formData, levels: newLevels });
                    }}
                    className="absolute opacity-0"
                  />
                  <span
                    className={`text-sm font-medium ${
                      formData.levels.includes(level)
                        ? "text-blue-600 dark:text-blue-400"
                        : "text-gray-600 dark:text-gray-400"
                    }`}
                  >
                    {level}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>

        {/* URLs Section */}
        <div className="space-y-6">
          <div>
            <label
              htmlFor="logoUrl"
              className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2"
            >
              Logo URL
            </label>
            <input
              type="url"
              id="logoUrl"
              placeholder="Enter logo URL"
              value={formData.logoUrl}
              onChange={(e) =>
                setFormData({ ...formData, logoUrl: e.target.value })
              }
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-colors duration-200"
            />
          </div>

          <div>
            <label
              htmlFor="url"
              className="block text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2"
            >
              Resource URL
            </label>
            <input
              type="url"
              id="url"
              required
              placeholder="Enter resource URL"
              value={formData.url}
              onChange={(e) =>
                setFormData({ ...formData, url: e.target.value })
              }
              className="w-full px-4 py-3 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white placeholder-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-400 focus:border-transparent transition-colors duration-200"
            />
          </div>
        </div>

        {/* Highlight Option */}
        <div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={formData.isHighlighted}
              onChange={(e) =>
                setFormData({ ...formData, isHighlighted: e.target.checked })
              }
              className="sr-only peer"
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-3 text-sm font-medium text-gray-700 dark:text-gray-300">
              Highlight this resource
            </span>
          </label>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-black dark:bg-white dark:text-black rounded-md hover:bg-gray-800 dark:hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {mode === "add" ? "Create" : "Update"} Resource
          </button>
        </div>
      </form>
    </Modal>
  );
};
