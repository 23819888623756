import {
  CreateResourceRequestBody,
  CreateResourceResponseBody,
  DeleteResourceResponseBody,
  GetResourceByIdResponseBody,
  GetResourcesResponseBody,
  IncreaseResourceClicksCountResponseBody,
  PaginationParams,
  UpdateResourceResponseBody,
} from "../types/api.types";
import api from "./api";



export const resourceApis = {
  getResources: (params?: PaginationParams) =>
    api.get<GetResourcesResponseBody>("/resource", {
      params: {
        page: params?.page || 1,
        limit: params?.limit || 20,
        filter: params?.filter,
        sortBy: params?.sortBy,
      },
    }),

  createResource: (data: CreateResourceRequestBody) =>
    api.post<CreateResourceResponseBody>("/resource", data),

  updateResource: (id: string, data: Partial<CreateResourceRequestBody>) =>
    api.patch<UpdateResourceResponseBody>(`/resource/${id}`, data),

  delete: (id: string) =>
    api.delete<DeleteResourceResponseBody>(`/resource/${id}`),

  getResourceById: (id: string) =>
    api.get<GetResourceByIdResponseBody>(`/resource/${id}`),

  increaseResourceClicksCount: (id: string) =>
    api.get<IncreaseResourceClicksCountResponseBody>(`/resource/${id}/clicks`),
};

export default resourceApis;
