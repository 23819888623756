import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { articleApis } from "../services/article.service";
import { convertFromRaw, EditorState } from "draft-js";
import { convertToHTML, IConvertToHTMLConfig } from "draft-convert";

type ArticleData = {
  articleId: string;
  title: string;
  content: string;
  createdAt: string;
};

export const ArticleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState<ArticleData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // HTML conversion configuration
  const htmlConfig: IConvertToHTMLConfig = {
    styleToHTML: (style) => {
      switch (style) {
        case "BOLD":
          return <strong className="font-bold" />;
        case "ITALIC":
          return <em className="italic" />;
        case "UNDERLINE":
          return <u className="underline" />;
        default:
          return null;
      }
    },
    blockToHTML: (block) => {
      switch (block.type) {
        case "header-one":
          return <h1 className="text-2xl font-bold mb-4" />;
        case "header-two":
          return <h2 className="text-xl font-bold mb-3" />;
        case "unstyled":
          return <p className="mb-4" />;
        default:
          return null;
      }
    },
  };

  useEffect(() => {
    loadArticle();
  }, [id]);

  const loadArticle = async () => {
    if (!id) {
      setError("Article ID is missing");
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await articleApis.getArticle(id);
      if (response.data.data?.article) {
        setArticle({
          articleId: response.data.data.article.articleId,
          title: response.data.data.article.title,
          content: response.data.data.article.content,
          createdAt: response.data.data.article.createdAt,
        });
      } else {
        setError("Article not found");
      }
    } catch (error) {
      console.error("Failed to load article:", error);
      setError("Failed to load article");
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return null;

    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  const renderContent = (content: string) => {
    try {
      // Try to parse as Draft.js content
      const parsedContent = JSON.parse(content);
      if (parsedContent.blocks) {
        const contentState = convertFromRaw(parsedContent);
        const editorState = EditorState.createWithContent(contentState);
        const html = convertToHTML(htmlConfig)(editorState.getCurrentContent());
        return (
          <div
            dangerouslySetInnerHTML={{ __html: html }}
            className="article-content"
          />
        );
      }
      throw new Error("Not valid Draft.js content");
    } catch {
      // If parsing fails or content is not Draft.js format, render as plain text
      return content.split("\n").map(
        (paragraph, index) =>
          paragraph.trim() && (
            <p
              key={index}
              className="text-gray-700 dark:text-gray-300 mb-3 text-md"
            >
              {paragraph}
            </p>
          )
      );
    }
  };

  if (loading) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-gray-400"></div>
        </div>
      </div>
    );
  }

  if (error || !article) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="text-center">
          <p className="text-gray-600 dark:text-gray-400 mb-4">
            {error || "Article not found"}
          </p>
          <button
            onClick={() => navigate("/articles")}
            className="text-blue-600 dark:text-blue-400 hover:underline"
          >
            Back to Articles
          </button>
        </div>
      </div>
    );
  }

  const formattedDate = formatDate(article.createdAt);

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <article>
        <div className="mb-8">
          <h1 className="text-3xl font-semibold text-gray-900 dark:text-white mb-4">
            {article.title}
          </h1>
          <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
            {formattedDate && (
              <>
                <time>{formattedDate}</time>
              </>
            )}
          </div>
        </div>

        <div className="prose prose-base dark:prose-invert max-w-none text-gray-700 dark:text-gray-300">
          {renderContent(article.content)}
        </div>
      </article>
    </div>
  );
};
