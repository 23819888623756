import {
  LanguageLevels,
  ResourceStatuses,
  ResourceTypes,
} from "../constants/enums";

export interface ApiError {
  response: {
    data: {
      code: string;
      message: string;
    };
  };
}
export interface PaginationParams {
  page?: number;
  limit?: number;
  filter?: string;
  sortBy?: string;
}

export interface Resource {
  _id?: string;
  title: string;
  description?: string;
  adminId: string;
  type: ResourceTypes;
  levels: LanguageLevels[];
  logoUrl: string;
  url: string;
  status: ResourceStatuses;
  clicksCount: number;
  isHighlighted: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateResourceRequestBody {
  title: string;
  description?: string;
  type: ResourceTypes;
  levels: LanguageLevels[];
  logoUrl: string;
  url: string;
  isHighlighted: boolean;
}

export interface CreateResourceResponseBody {}

export interface GetResourcesResponseBody {
  data: {
    resources: {
      resourceId: string;
      title: string;
      description: string;
      type: ResourceTypes;
      levels: LanguageLevels[];
      logoUrl: string;
      url: string;
      clicksCount: number;
      isHighlighted: boolean;
    }[];
    resourceCount: number;
  };
}

export interface UpdateResourceResponseBody {}

export interface DeleteResourceResponseBody {}

export interface GetResourceByIdResponseBody {
  data: {
    resource: {
      resourceId: string;
      title: string;
      description: string;
      type: ResourceTypes;
      levels: LanguageLevels[];
      logoUrl: string;
      url: string;
      clicksCount: number;
      isHighlighted: boolean;
    };
  };
}

export interface IncreaseResourceClicksCountResponseBody {}

export interface LoginRequestBody {
  email: string;
  password: string;
}

export interface LoginResponseBody {}

export interface CreateContactRequestBody {
  title: string;
  description: string;
}

export interface UpdateIsContactReadRequestBody {
  isRead: boolean;
}

export interface Contact {
  contactId: string;
  title: string;
  description: string;
  isRead: boolean;
  createdAt: string;
}

export interface GetContactsResponseBody {
  data: {
    contacts: Contact[];
  };
}

export interface GetContactResponseBody {
  data: {
    contact: Contact;
  };
}

export interface CreateArticleRequestBody {
  title: string;
  content: string;
}

export interface UpdateArticleRequestBody {
  title: string;
  content: string;
}

export interface GetArticleResponseBody {
  data: {
    article: {
      articleId: string;
      title: string;
      content: string;
      createdAt: string;
    };
  };
}

export interface GetArticlesResponseBody {
  data: {
    articles: {
      articleId: string;
      title: string;
      content: string;
      createdAt: string;
    }[];
    articleCount: number;
  };
}

export const MAX_TITLE_LENGTH = 100;
