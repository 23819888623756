import {
  CreateContactRequestBody,
  GetContactResponseBody,
  GetContactsResponseBody,
  UpdateIsContactReadRequestBody,
} from "../types/api.types";
import api from "./api";

export const contactApis = {
  createContact: (data: CreateContactRequestBody) => api.post("/contact", data),
  getContacts: () => api.get<GetContactsResponseBody>("/contact"),
  updateIsContactRead: (id: string, data: UpdateIsContactReadRequestBody) =>
    api.patch(`/contact/${id}`, data),
  getContact: (id: string) =>
    api.get<GetContactResponseBody>(`/contact/${id}`),

};

export default contactApis;
