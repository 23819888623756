import { useEffect, useState } from "react";
import { useTheme } from "../../contexts/ThemeContext";
import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  ContentState,
  getDefaultKeyBinding,
} from "draft-js";
import "draft-js/dist/Draft.css";

type ArticleFormData = {
  title: string;
  content: string;
};

type ArticleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ArticleFormData) => void;
  initialData?: {
    title: string;
    content: string;
  };
  mode: "add" | "edit";
};

export const ArticleModal = ({
  isOpen,
  onClose,
  onSubmit,
  initialData,
  mode,
}: ArticleModalProps) => {
  const [title, setTitle] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const { isDarkMode } = useTheme();

  const styleMap = {
    CODE: {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  const mapKeyToEditorCommand = (e: React.KeyboardEvent): string | null => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(e, editorState, 4);
      if (newEditorState !== editorState) {
        setEditorState(newEditorState);
      }
      return null;
    }
    return getDefaultKeyBinding(e);
  };

  useEffect(() => {
    if (isOpen) {
      if (initialData) {
        setTitle(initialData.title || "");
        try {
          // Try to parse as Draft.js content
          const parsedContent = JSON.parse(initialData.content);
          if (parsedContent.blocks) {
            const contentState = convertFromRaw(parsedContent);
            setEditorState(EditorState.createWithContent(contentState));
          } else {
            // If not valid Draft.js content, create plain text
            const contentState = ContentState.createFromText(
              initialData.content
            );
            setEditorState(EditorState.createWithContent(contentState));
          }
        } catch {
          // If parsing fails, create plain text
          const contentState = ContentState.createFromText(initialData.content);
          setEditorState(EditorState.createWithContent(contentState));
        }
      } else {
        setTitle("");
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [initialData, isOpen]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!title.trim()) return;

    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    onSubmit({
      title: title.trim(),
      content: JSON.stringify(rawContent),
    });
  };

  const handleKeyCommand = (command: string, editorState: EditorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style: string) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const toggleBlockType = (blockType: string) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="fixed inset-0 bg-black opacity-30"></div>
        <div className="relative w-full max-w-2xl rounded-lg bg-white p-6 dark:bg-gray-800">
          <div className="max-h-[80vh] overflow-y-auto">
            <h2 className="mb-4 text-xl font-semibold text-gray-900 dark:text-white">
              {mode === "add" ? "Create New Article" : "Edit Article"}
            </h2>

            <div className="mb-4">
              <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                Title
              </label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                placeholder="Enter article title"
              />
            </div>

            <div className="mb-4">
              <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
                Content
              </label>
              <div className="mb-2 flex flex-wrap gap-2">
                <button
                  type="button"
                  onClick={() => toggleInlineStyle("BOLD")}
                  className="px-3 py-1 text-sm rounded bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  Bold
                </button>
                <button
                  type="button"
                  onClick={() => toggleInlineStyle("ITALIC")}
                  className="px-3 py-1 text-sm rounded bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  Italic
                </button>
                <button
                  type="button"
                  onClick={() => toggleInlineStyle("UNDERLINE")}
                  className="px-3 py-1 text-sm rounded bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  Underline
                </button>
                <button
                  type="button"
                  onClick={() => toggleBlockType("header-one")}
                  className="px-3 py-1 text-sm rounded bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  H1
                </button>
                <button
                  type="button"
                  onClick={() => toggleBlockType("header-two")}
                  className="px-3 py-1 text-sm rounded bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  H2
                </button>
              </div>
              <div className="min-h-[300px] rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus-within:border-blue-500 focus-within:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus-within:border-blue-500 dark:focus-within:ring-blue-500">
                <Editor
                  editorState={editorState}
                  onChange={setEditorState}
                  handleKeyCommand={handleKeyCommand}
                  keyBindingFn={mapKeyToEditorCommand}
                  placeholder="Enter article content"
                  customStyleMap={styleMap}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="rounded-lg bg-blue-600 px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600"
              >
                {mode === "add" ? "Create" : "Update"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
